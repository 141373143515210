import { Box, Button } from '@mui/material';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';

import './App.css';
import NavBar  from '../NavBar/NavBar';//, {SiteMapNav} from '../NavBar/NavBar';
import Gallery from '../Gallery/Gallery';
// import AvailabilityCalendar from '../AvailabilityCalendar/AvailabilityCalendar';
// import { useNavigate } from 'react-router-dom';

function App({view='res'}) {

    // const [content, setContent] = useState<string>(view);
    // const navigate = useNavigate();

    // const navButtonClicked = (value: string) => {
    //     if (Object.hasOwn(SiteMapNav, value)) navigate(SiteMapNav[value]);
    //     setContent(value);
    // };

    return (
    <div className="App">

        <div className="nav">
            {/* <NavBar buttonClicked={navButtonClicked}></NavBar> */}
            <NavBar></NavBar>
        </div>
        <div className="content">

            <div className="hero-image"></div>
            <img className="hero-logo" alt="Sabertooth Tree Service Logo" src="/sbtall.png"></img>

            <div className="main">
                <Box id="services" className='anchor'>
                    <h2>Services</h2>
                    <ul>
                        {/* <li>ISA certified (end of March 2024) and insured.</li> */}
                        <li>Our services include:</li>
                        <ul>
                            <li>Any size tree removal, technical rigging, crane work</li>
                            <li>Trained professionals in pruning techniques to improve the health and aesthetics of your tree.</li>
                            <li>Major Deadwood removal.</li>
                            <li>Elevation.</li>
                            <li>Tree assessment and permit application for our clients.</li>
                        </ul>
                    </ul>
                    <p>We are ISA Certified and able to provide expert assessment and permit application for our clients. <b>Please note</b>: Distinctive Trees 50cm diameter or greater at a height 1.4m from the ground require a permit in the City of London</p>
                    <img className="isa-image" alt="Certified Arborist" src="https://pub-9d050830c91949498aa6cf0be06c379d.r2.dev/cert_arb.jpg"/>
                </Box>

                <Box id="gallery" className='anchor'>
                    <Gallery/>
                </Box>

                <Box id="contact" className='anchor'>
                    <h2>Contact</h2>
                    <p>We look forward to hearing from you. When you reach out, please include:</p>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Phone Number</li>
                        <li>Description of the service required.</li>
                    </ul>
                    <Button
                        startIcon={<EmailIcon/>}
                        variant="contained"
                        href="mailto:lucas@sabertoothtree.ca">
                        Send us an email
                    </Button>
                    {/* <Box className='footer'>
                        <IconButton href="mailto:lucas@sabertoothtree.ca">
                            <EmailIcon/>
                        </IconButton>
                        <IconButton href="https://www.instagram.com/canadianarborist">
                            <InstagramIcon/>
                        </IconButton>
                        <IconButton href="https://www.facebook.com/profile.php?id=100078838844388">
                            <FacebookIcon/>
                        </IconButton>
                    </Box> */}
                </Box>
                <br/>
            </div>
        </div>
    </div>

  );
}

export default App;
