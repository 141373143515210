import React, { FC } from 'react';
import { AppBar, Toolbar, Box, Button, IconButton } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
// import ForestIcon from '@mui/icons-material/Forest';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import SettingsIcon from '@mui/icons-material/Settings'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EmailIcon from '@mui/icons-material/Email';

let SiteMapNav = {
    'res': '#services',
    // 'sub': '/contract',
    // 'cal': '/availability',
    'pix': '#gallery',
    'con': '#contact'
};

let Icons = {
    'res': <HomeIcon/>,
    // 'sub': <ForestIcon/>,
    // 'cal': <CalendarMonthIcon/>,
    'pix': <PhotoLibraryIcon/>,
    'con': <EmailIcon/>
};

let Verbiage = {
    'res': 'About Us',
    // 'sub': 'Subcontracting',
    // 'cal': 'Availability',
    'pix': 'Gallery',
    'con': 'Contact'
}


interface NavBarProps {
    // buttonClicked: (value: string) => void;
}

const NavBar: FC<NavBarProps> = (props:NavBarProps) => {
    
    // const clicked = (value: string) => props.buttonClicked(value);

    const mobileButtons: Object[] = [];
    const desktopButtons: Object[] = [];
    for (let abbr of Object.keys(SiteMapNav)) {
        // mobileButtons.push(<IconButton key={abbr} color="inherit" onClick={() => clicked(abbr)}>{Icons[abbr]}</IconButton>);
        // desktopButtons.push(<Button key={abbr} color="inherit" startIcon={Icons[abbr]} onClick={() => clicked(abbr)}>{Verbiage[abbr]}</Button>);
        mobileButtons.push(<IconButton key={abbr} color="inherit" href={SiteMapNav[abbr]}>{Icons[abbr]}</IconButton>);
        desktopButtons.push(<Button key={abbr} color="inherit" startIcon={Icons[abbr]} href={SiteMapNav[abbr]}>{Verbiage[abbr]}</Button>);
    }

    return (
    <Box sx={{ flexGrow: 1}}>
        <AppBar position="fixed">
            <Toolbar>
                {/* Mobile */}
                <Box sx={{display: {xs: 'inline-flex', sm: 'none'}, flexGrow:1}}>
                    <>{ mobileButtons }</>
                    {/* <Box sx={{flexGrow: 1}}/>
                    <IconButton color="inherit" href="/admin">
                        <SettingsIcon/>
                    </IconButton> */}
                </Box>

                {/* Regular */}
                <Box sx={{display: {xs: 'none', sm: 'inline-flex'}, flexGrow:1}}>
                    <>{ desktopButtons }</>
                    {/* <Box sx={{flexGrow: 1}}/>
                    <IconButton color="inherit" href="/admin">
                        <SettingsIcon/>
                    </IconButton> */}
                </Box>
            </Toolbar>
        </AppBar>
    </Box>);
};

export { SiteMapNav };
export default NavBar;
