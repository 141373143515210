import React from 'react';
import ReactDOM from 'react-dom/client';
// import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import { applyMiddleware, createStore } from 'redux';
// import { Provider } from 'react-redux';
// import { composeWithDevTools } from "redux-devtools-extension";

import './index.css';
import App from './components/App/App';
// import AdminCalendar from './components/AdminCalendar/AdminCalendar';
// import Login from './components/Login/Login';
// import ErrorPage from './components/ErrorPage/ErrorPage';
// import { SiteMapNav } from './components/NavBar/NavBar';

import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
// import { State, availabilityReducer } from "./reducers/Availability"
// import { getAvailabilityMiddleware } from "./middleware/Availability";

// // setup public (SiteMap) and private routes
// let routes: Object[] = [];
// for (let [abbr, route] of Object.entries(SiteMapNav)) routes.push({path: route, element: <App view={abbr}/>, errorElement: <ErrorPage/>});
// // routes.push({ path: '/admin',        element: <AdminCalendar />,   errorElement: <ErrorPage/> });
// // routes.push({ path: '/login',        element: <Login />,           errorElement: <ErrorPage/> });
// const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// TODO move this into the component?
// const getAvailabilityEnhancer = applyMiddleware(getAvailabilityMiddleware);
// const composedEnhancers = composeWithDevTools(getAvailabilityEnhancer)
// const store = createStore(availabilityReducer, composedEnhancers)
// const store = createStore(availabilityReducer, getAvailabilityEnhancer)
// let envLoaded: boolean = false;
// store.subscribe(() => {
//     let state: State = store.getState();
//     if ('api' in state && state['api'] != null && !envLoaded) {
//         envLoaded = true;
//         store.dispatch({type: 'availability/fetch', payload: null});
//     }
// });
// store.dispatch({type: 'availability/config', payload: null});


root.render(
  <React.StrictMode>
    <CssBaseline />
    {/* <Provider store={store}> */}
    {/* <RouterProvider router={router} /> */}
    <App/>
    {/* </Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// function composeWithDevTools(getAvailabilityEnhancer: StoreEnhancer<{ dispatch: unknown; }, {}>) {
//     throw new Error('Function not implemented.');
// }

